<template>
  <div v-if="quote" id="order-calendar-view-services">
    <b-card no-body>
      <b-card-body>
        <div class="d-block d-sm-flex justify-content-between align-items-end">
          <h4>
            {{ $t('order.service_tracking') }}
          </h4>
          <b-button
            v-if="quote.lines && selectedOrganization.seller"
            id="order-view-service-update-all-services-to-delivered"
            v-ripple.400
            :block="$store.getters[`app/smAndDown`]"
            variant="outline-primary"
            @click.prevent="updateAllServicesToDelivered()"
          >
            <span class="text-nowrap">
              {{ $t('order.update_all_services_to_delivered') }}
            </span>
          </b-button>
        </div>
        <div class="d-flex justify-content-start align-items-end mt-1 note-min-width">
          <span>{{ $t('common.note') }} {{ $t('order.service_tracking_note') }}</span>
        </div>
      </b-card-body>
      <validation-observer ref="order-calendar-view-services-observer">
        <b-table
          ref="order-calendar-view-services"
          :class="['position-relative', 'table-dropdown-action']"
          thead-class="text-nowrap"
          :stacked="$store.getters[`app/mdAndDown`]"
          :items="quote.lines"
          :fields="linesDocumentFields"
          :empty-text="$t('common.no_record_found')"
        >
          <!-- LOADING -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle" />
            </div>
          </template>
          <!-- CELL -->
          <!-- date -->
          <template #cell(date)="data">
            <b-form-group class="mt-md-1 input-group">
              <flat-pickr
                :id="`order-view-service-${data.item.id}-date`"
                v-model="data.item.date"
                class="form-control"
                :config="{
                  altInput: true,
                  altFormat: 'F j, Y H:i',
                  dateFormat: 'Y-m-dTH:i',
                  enableTime: true,
                  time_24hr: true,
                  locale: $i18n.locale,
                }"
                @on-close="updateLineDate(data.item)"
              />
            </b-form-group>
          </template>
          <!-- status -->
          <template #cell(status)="data">
            <b-form-group class="mt-md-1 input-group">
              <enum-select
                v-if="selectedOrganization.seller"
                :id="`order-view-service-${data.item.id}-status`"
                v-model="data.item.status"
                :placeholder="$t('common.status')"
                :clearable="false"
                :searchable="false"
                enum-class="quoteLineStatus"
                required
                @input="updateLineStatus(data.item)"
              >
                <template #option="{ value }">
                  <b-badge pill :variant="`${resolveSupplyLineStatusVariant(value)}`">
                    {{ value | enumTranslate('quote_line_status') }}
                  </b-badge>
                </template>
                <template #selected-option="{ value }">
                  <b-badge pill :variant="`${resolveSupplyLineStatusVariant(value)}`">
                    {{ value | enumTranslate('quote_line_status') }}
                  </b-badge>
                </template>
              </enum-select>
              <span v-else>
                <b-badge pill :variant="`${resolveSupplyLineStatusVariant(data.item.status)}`">
                  {{ data.item.status | enumTranslate('quote_line_status') }}
                </b-badge>
              </span>
            </b-form-group>
          </template>
        </b-table>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import UIHelper from '@/helpers/ui'

import {
  patchQuoteQuoteLinesDatesRequest,
  patchQuoteQuoteLinesStatusRequest,
} from '@/request/globalApi/requests/quoteRequests'
import { mapState } from 'vuex'

export default {
  name: 'OrdersCalendarOrderResumeServices',

  mixins: [UIHelper],

  props: {
    quote: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    ...mapState('auth', ['selectedOrganization']),

    linesDocumentFields() {
      const linesDocumentFields = [
        {
          key: 'name',
          label: this.$t('common.name'),
        },
      ]
      if (this.quote.state === 'awaiting_for_services_completed' || this.quote.state === 'delivered') {
        linesDocumentFields.push(
          {
            key: 'date',
            label: this.$t('common.date'),
          },
          {
            key: 'status',
            label: this.$t('common.status'),
            thStyle: 'width: 18em',
            tdStyle: 'width: 18em',
          },
        )
      }
      return linesDocumentFields
    },
  },

  methods: {

    updateLineDate(line) {
      if (line.date) {
        patchQuoteQuoteLinesDatesRequest(
          this.quote.id,
          { quoteLinesDates: [{ quoteLineId: line.id, date: line.date }] },
          this.$store.getters['quote/quoteTitleAndId'],
        )
          .then(() => {
            this.$emit('refetch-quote')
          })
      }
    },

    updateLineStatus(line) {
      patchQuoteQuoteLinesStatusRequest(
        this.quote.id,
        { quoteLinesStatus: [{ quoteLineId: line.id, status: line.status }] },
        this.$store.getters['quote/quoteTitleAndId'],
      )
        .then(() => {
          this.$emit('refetch-quote')
        })
    },

    updateAllServicesToDelivered() {
      const quoteLinesStatus = []
      this.quote.lines.forEach(line => {
        quoteLinesStatus.push({ quoteLineId: line.id, status: 'delivered' })
      })
      patchQuoteQuoteLinesStatusRequest(this.quote.id, { quoteLinesStatus }, this.$store.getters['quote/quoteTitleAndId'])
        .then(() => {
          this.$emit('refetch-quote')
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.input-group {
  min-width: 160px;
}
td, th {
  padding: 10px;
}
.note-min-width {
  min-width: 300px;
}
</style>
