<template>
  <section>
    <b-overlay :show="quotesCalendarLoading" spinner-medium>
      <b-card class="app-calendar overflow-hidden">
        <div class="row no-gutters">
          <div class="col position-relative">
            <full-calendar ref="calendarSlot" :options="calendarOptions" :plugins="calendarOptions.plugins" class="full-calendar" />
          </div>
        </div>
      </b-card>
    </b-overlay>
    <OrdersCalendarOrderResume :is-order-resume-sidebar-active.sync="isOrderResumeSidebarActive" :order="orderSelected" />
  </section>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import { mapActions, mapState } from 'vuex'

import UIHelper from '@/helpers/ui'
import OrdersCalendarOrderResume from '@/views/order/OrdersCalendarOrderResume.vue'
import momentPlugin from '@fullcalendar/moment'

export default {
  name: 'OrdersCalendar',

  components: {
    FullCalendar,
    OrdersCalendarOrderResume,
  },

  mixins: [UIHelper],
  data() {
    return {
      isOrderResumeSidebarActive: false,
      orderSelected: {},
      calendarOptions: {
        plugins: [momentPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'timeGridWeek',
        allDaySlot: false,
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'timeGridWeek,listMonth',
        },
        events: [],
        selectable: true,
        eventClick: order => {
          if (this.$can('TRIP_ACCOUNTING_EDIT')) {
            this.orderSelected = order.event
            setTimeout(() => {
              this.isOrderResumeSidebarActive = true
            }, 100)
          }
        },
        datesSet: event => {
          if (new Date(this.currentCalendarRange.start).getTime() !== new Date(event.start).getTime()) {
            this.setCurrentCalendarRange({ start: event.start, end: event.end })
            this.fetchQuotesCalendar()
          }
        },
        eventResizableFromStart: true,
        dragScroll: true,
        navLinks: true,
        eventTimeFormat: {
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
          meridiem: false,
        },
        slotLabelFormat: {
          // like '14:30:00'
          hour: '2-digit',
          minute: '2-digit',
          hourCycle: 'h23',
          meridiem: false,
        },
        dayHeaderFormat: 'dd, D MMM',
        locale: this.$i18n.locale,
      },
      eventSkeleton: {
        id: null,
        title: '',
        start: null,
        end: null,
        allDay: false,
        color: '#dcc181',
        textColor: '#fff',
        extendedProps: null,
      },
    }
  },
  computed: {
    ...mapState('quote', ['quotesCalendarLoading', 'quotesCalendar', 'currentCalendarRange', 'dateCalendarSelected']),
  },
  watch: {
    dateCalendarSelected(val) {
      const calendarApi = this.$refs.calendarSlot.getApi()
      calendarApi.gotoDate(val)
    },
    quotesCalendar() {
      this.buildEvent()
    },
  },
  mounted() {
    this.fetchQuotesCalendar()
  },
  methods: {
    ...mapActions('quote', ['fetchQuotesCalendar', 'setCurrentCalendarRange']),
    // Create calendar event for each quote
    buildEvent() {
      this.calendarOptions.events.splice(0)
      this.quotesCalendar.forEach(order => {
        const event = JSON.parse(JSON.stringify(this.eventSkeleton))
        event.id = order.id
        event.title = `${this.$t('order.order.title')} ${order.reference}`
        // Start
        const start = this.$moment(order.date)
        event.start = new Date(
          start.set({
            h: this.$moment(start, 'HH:mm').toDate().getHours(),
            m: this.$moment(start, 'HH:mm').toDate().getMinutes(),
          }),
        )
        if (order.cancelled || order.refused) event.color = '#EA5455'

        console.log(order)
        event.extendedProps = {
          order,
        }
        this.calendarOptions.events.push(event)
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';
.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
}

.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-col-header {
  width: 100% !important;
}

.fc-selectDateBtn-button {
  margin-left: 2rem !important;
  border-color: #dcc181 !important;
  background-color: #dcc181 !important;
}
</style>
