import { render, staticRenderFns } from "./OrdersCalendarOrderResume.vue?vue&type=template&id=7ab09ff2&"
import script from "./OrdersCalendarOrderResume.vue?vue&type=script&lang=js&"
export * from "./OrdersCalendarOrderResume.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports