<template>
  <b-sidebar id="sidebar-orderResume" :visible="isOrderResumeSidebarActive" @change="val => $emit('update:is-order-resume-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0 ppr-no-print">
          {{ `${$t('order.order.title')} ${order.extendedProps.order.reference}` }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer ppr-no-print" icon="XIcon" size="16" @click="hide" />
      </div>
      <b-overlay :show="quoteLoading" spinner-medium>
        <!-- RESUME -->
        <div class="my-1 mx-2">
          <section v-if="order.extendedProps.order && quote" class="mb-1">
            <div v-if="order.extendedProps.order.organization.id === selectedOrganization.id && order.extendedProps.order.seller" class="mb-1"><font-awesome-icon class="mr-50" icon="user" /><span class="font-weight-bold">{{ $t('order.seller') }}</span><span class="mx-50">:</span><span>{{ order.extendedProps.order.seller.legalName }}</span></div>
            <div v-if="order.extendedProps.order.organization.id !== selectedOrganization.id && order.extendedProps.order.organization" class="mb-1"><font-awesome-icon class="mr-50" icon="user" /><span class="font-weight-bold">{{ $t('order.customer') }}</span><span class="mx-50">:</span><span>{{ order.extendedProps.order.organization.legalName }}</span></div>
            <div class="mb-1"><font-awesome-icon class="mr-50" icon="plane-departure" /><span class="font-weight-bold">{{ $t('trip.event.departure_to') }}</span><span class="mx-50">:</span><span>{{ quote.tripStepEvent.airport.name }} - {{ quote.tripStepEvent.airport.icaoCode }}</span></div>
            <div><font-awesome-icon class="mr-50" icon="clock" /><span class="font-weight-bold">{{ $t('trip.event.etd_utc') }}</span><span class="mx-50">:</span><span>{{ $moment.utc(quote.tripStepEvent.eventDateTime).format('ddd, MMM Do YYYY HH:mm') }} {{ $t('common.utc') }}</span></div>
            <div class="mb-1"><font-awesome-icon class="mr-50" icon="clock" /><span class="font-weight-bold">{{ $t('trip.event.etd') }}</span><span class="mx-50">:</span><span>{{ $moment(quote.tripStepEvent.eventDateTime).format('ddd, MMM Do YYYY HH:mm') }}</span></div>
            <div><font-awesome-icon class="mr-50" icon="plane" /><span class="font-weight-bold">{{ quote.tripStepEvent.organizationAircraft.type }}</span></div>
            <div><span class="font-weight-bold">{{ $t('aircraft.call_sign') }}</span><span class="mx-50">:</span><span>{{ quote.tripStepEvent.organizationAircraft.callSign }}</span></div>
            <div><span class="font-weight-bold">{{ $t('order.registration_number') }}</span><span class="mx-50">:</span><span>{{ quote.tripStepEvent.organizationAircraft.registration }}</span></div>
            <div class="mb-1"><span class="font-weight-bold">{{ $t('order.flight_type') }}</span><span class="mx-50">:</span><span>{{ upperFirst(quote.tripStepEvent.flightType) }}</span></div>
            <div><span class="font-weight-bold">{{ $t('order.vat_free') }}</span><span class="mx-50">:</span><span>{{ quote.totalPriceExcludingTaxes }} {{ currency }}</span></div>
          </section>
        </div>
        <!-- LINES -->
        <OrdersCalendarOrderResumeServices :quote="quote" @refetch-quote="refetchQuote" />
        <div class="my-1 mx-2">
          <b-card v-if="order.extendedProps.order.id">
            <b-button block :to="{ name: 'OrderViewShow', params: { id: order.extendedProps.order.id } }">
              <font-awesome-icon icon="file-invoice" class="mr-50" />
              {{ $t('order.view_order') }}
            </b-button>
            <b-button block @click="hide()">
              <font-awesome-icon icon="xmark" class="mr-50" />
              {{ $t('action.close') }}
            </b-button>
          </b-card>
        </div>
      </b-overlay>
    </template>
  </b-sidebar>
</template>
<script>

import { fetchQuoteCalendarRequest } from '@/request/globalApi/requests/quoteRequests'
import OrdersCalendarOrderResumeServices from '@/views/order/OrdersCalendarOrderResumeServices.vue'
import { upperFirst } from 'lodash'
import getCurrency from '@/helpers/getCurrency'
import { mapState } from 'vuex'

export default {
  name: 'OrdersCalendarOrderResume',
  components: {
    OrdersCalendarOrderResumeServices,
  },

  model: {
    prop: 'isOrderResumeSidebarActive',
    event: 'update:is-order-resume-sidebar-active',
  },
  props: {
    isOrderResumeSidebarActive: {
      type: Boolean,
      required: true,
    },
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      quote: null,
      currency: null,
      quoteLoading: false,
    }
  },
  computed: {
    ...mapState('auth', ['selectedOrganization']),
  },
  watch: {
    order(val) {
      if (val.extendedProps.order.id) this.getQuote()
    },
  },
  mounted() {
    this.currency = getCurrency()
  },
  methods: {
    upperFirst,
    getQuote() {
      this.quoteLoading = true
      fetchQuoteCalendarRequest(this.order.extendedProps.order.id, { date: this.order.extendedProps.order.date }).then(response => {
        this.quote = response.data
        this.quoteLoading = false
      })
    },
    refetchQuote() {
      this.getQuote()
    },
  },
}
</script>
